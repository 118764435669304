import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="aiic-logo-black.svg" width="300" />
    <h1>{`Kia Ora`}</h1>
    <p>{`Welcome to the Auckland International Innovation Centre (AIIC).`}</p>
    <p>{`This is a place scattered with fresh knowledge, cutting-edge technologies, and crazy ideas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      