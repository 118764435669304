import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`AIIC is founded by a group of scholar and entrepreneur based in New Zealand and USA.`}</p>
    <p>{`AIIC aims to create a platform for cross-border cooperation and innovation in science and technology. It seeks to promote better understanding and a bridge between different rules, languages, and cultures. Also, it helps to safeguard the common concerns in such cooperation such as IP, security, commercializing, regulating, etc.`}</p>
    <p>{`AIIC is based in Auckland and it targets New Zealand and countries having close relationships with New Zealand.`}</p>
    <p>{`AIIC is open for any individual or organisation that has a passion for boosting technology innovation and cooperation.`}</p>
    <p>{`Please visit our Wainui centre to explore more.`}</p>
    <img src="wainui0.png" />
    <img src="wainui1.png" />
    <img src="wainui2.png" />
    <p>{`Our Wainui Centre offers a great place to meet with innovation people and have your ideas spread. Coffee and catering are available 7 days a week. It is also an excellent conference venue which can hold up to 150 people.`}</p>
    <p>{`Please do not hesitate to contact us for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      