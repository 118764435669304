import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "info@aiic.nz"
      }}>{`Hi`}</a>{` or find us on other platforms: `}<a parentName="p" {...{
        "href": "#"
      }}>{`WeChat`}</a>{` (coming up).`}</p>
    <p>{`Our address:
379 Wainui Rd, Wainui
Auckland, 0992
New Zealand`}</p>
    <p>{`Phone number:
+64221363219
+6499735222`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      